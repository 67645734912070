export default [
  {
    route: '/',
    title: 'Início',
  },
  {
    route: `/privacidade-clientes/`,
    title: `Declaração de Privacidade de quem compra`,
  },
  {
    route: `/privacidade-entregadores/`,
    title: `Declaração de Privacidade de quem entrega`,
  },
]
