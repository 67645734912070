import React from 'react'
import Copyright from 'components/Footer/Copyright'
import About from 'components/Footer/About'

import './styles.scss'

const Footer = () => (
    <footer className="custom-footer bg-white">
      <About />
      <Copyright />
    </footer>
  )

export default Footer
