import { useState, useEffect } from 'react'

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobileDevice: undefined,
  })

  useEffect(() => {
    if (!window) {
      return null
    }
    function handleResize() {
      const width = window?.screen.width || window.innerWidth
      const height = window?.screen.height || window.innerHeight
      setWindowSize({
        width,
        height,
        isMobileDevice: width < 768,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return windowSize
}
