import React, { useState } from 'react'
import Button from 'components/Button'
import Logo from 'assets/svg/logo.svg'
import navigationLinks from 'utils/navigationLinks'
import { getUniqueId } from 'utils/helpers'

import NavLink from './NavLink'

import './styles.scss'

function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const links = React.useMemo(
    () => navigationLinks.map((link) => <NavLink key={getUniqueId()} link={link} />),
    [],
  )

  return (
    <header className={`bg-white main-header ${menuIsOpen ? 'is-active ' : ''}`} role="banner">
      <div className="main-header__content container">
        <nav className="main-header__navbar navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/" title="iFood">
              <Logo />
            </a>
          </div>

          <button
            className={`navbar-burger burger ${menuIsOpen ? 'is-active ' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </button>

          <div className={`navbar-menu ${menuIsOpen ? 'is-active ' : ''}`}>
            <div className="navbar-start">{links}</div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <Button secondary href="https://www.ifood.com.br/">
                    Peça o seu
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
