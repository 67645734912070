import React from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Main from 'components/Main'
import Footer from 'components/Footer'

import { withParseConfig } from 'plugins/content'
import { isLoggedIn } from 'plugins/content/services/auth'

import config from 'config/index'

import './styles.scss'

if (isLoggedIn()) withParseConfig({ parseConfig: config.parseConfig })

const Layout = ({ children }) => (
  <div>
    <Header />
    <Main>{children}</Main>
    <Footer />
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
