import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import ArrowDown from 'assets/svg/arrow-down-bold.svg'
import { FiArrowUpRight as ExternalLinkIcon } from 'react-icons/fi'
import useWindowSize from 'hooks/useWindowSize'

import './styles.scss'

const variants = {
  visible: {
    opacity: 1,
    pointerEvents: 'auto',
    y: '100%',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    y: '90%',
  },
}

const NavLink = ({ link }) => {
  const { width: windowWidth } = useWindowSize()
  const isMobileDevice = windowWidth && windowWidth < 1024
  const [isSubmenuVisible, setIsSubmenuVisible] = React.useState(false)
  if (link.children && !isMobileDevice) {
    return (
      <div
        className={`navbar-item navbar-item--submenu ${isSubmenuVisible ? 'submenu--active' : ''}`}
        onMouseOver={() => setIsSubmenuVisible(true)}
        onMouseLeave={() => setIsSubmenuVisible(false)}
        onFocus={() => setIsSubmenuVisible(true)}
      >
        <a className="navbar__link navbar-item" key={link.title} href={link.route}>
          {link.title}
          <ArrowDown className="navbar-item__arrow" />
        </a>
        {link.children ? (
          <motion.ul
            className="navbar-item__submenu"
            variants={variants}
            animate={isSubmenuVisible ? 'visible' : 'hidden'}
            transition={{
              duration: 0.025,
            }}
          >
            {link.children.map((linkChildren) => (
              <li key={linkChildren.title}>
                <a href={linkChildren.route} className="submenu__link">
                  {linkChildren.title}
                </a>
              </li>
            ))}
          </motion.ul>
        ) : null}
      </div>
    )
  }
  if (link.children && isMobileDevice) {
    return (
      <div className="navbar-submenu-mobile">
        <a className="navbar__link navbar-item" key={link.title} to={link.route}>
          {link.title}

          {link.children ? (
            <div className="navbar-submenu__link">
              {link.children.map((linkChildren) => (
                <li key={linkChildren.title}>
                  <a href={linkChildren.route}>{linkChildren.title}</a>
                </li>
              ))}
            </div>
          ) : null}
        </a>
      </div>
    )
  }

  return (
    <a className="navbar__link navbar-item" key={link.title} href={link.route}>
      {link.title}
      {link.isExternal && (
        <>
          &nbsp;
          <ExternalLinkIcon className="navbar__link__external-icon" />
        </>
      )}
    </a>
  )
}

NavLink.propTypes = {
  link: PropTypes.shape({
    title: PropTypes.string,
    route: PropTypes.string,
    isExternal: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}

export default NavLink
