import React from 'react'
import Facebook from 'assets/svg/facebook.svg'
import Twitter from 'assets/svg/twitter.svg'
import Youtube from 'assets/svg/youtube.svg'
import Instagram from 'assets/svg/instagram.svg'
import TikTok from 'assets/svg/tiktok.svg'

export const socialLinks = [
  {
    label: 'Instagram',
    icon: <Instagram />,
    href: 'https://www.instagram.com/ifoodbrasil',
  },
  {
    label: 'Facebook',
    icon: <Facebook />,
    href: 'https://www.facebook.com/iFood',
  },
  {
    label: 'Twitter',
    icon: <Twitter />,
    href: 'https://www.twitter.com/ifood',
  },
  {
    label: 'Youtube',
    icon: <Youtube />,
    href: 'https://www.youtube.com/user/ifooddelivery',
  },
  {
    label: 'TikTok',
    icon: <TikTok />,
    href: 'https://www.tiktok.com/@ifoodnewsbrasil',
  },
]
export const aboutLinks = [
  {
    title: 'iFood.com.br',
    href: 'https://ifood.com.br',
  },
  {
    title: 'Institucional',
    href: 'https://news.ifood.com.br/institucional/',
  },
  {
    title: 'Comunidade',
    href: 'https://news.ifood.com.br/comunidade/',
  },
  {
    title: 'Código de Ética e Conduta',
    href: 'https://news.ifood.com.br/codigo-de-etica/',
  },
  {
    title: 'Nossos Compromissos',
    href: 'https://news.ifood.com.br/nossos-compromissos/',
  },
  {
    title: 'Canal de Integridade',
    href: 'https://www.canaldeintegridade.com.br/ifood/',
  }
]
export const discoveryLinks = [
  {
    title: 'Carreiras',
    href: 'https://carreiras.ifood.com.br',
  },
  {
    title: 'Cadastre seu Restaurante ou Mercado',
    href: 'https://parceiros.ifood.com.br',
  },
  {
    title: 'Portal do Entregador',
    href: 'https://entregador.ifood.com.br',
  },
  {
    title: 'Portal para Parceiros',
    href: 'https://blog-parceiros.ifood.com.br',
  },
  {
    title: 'iFood Benefícios',
    href: 'https://beneficios.ifood.com.br',
  },
  {
    title: 'iFood Shop',
    href:
      'https://shop.ifood.com.br',
  },
  {
    title: 'iFood Card',
    href: 'https://contrate-beneficios.ifood.com.br/ifood-card',
  },
]
