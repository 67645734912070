import React from 'react'
import AppStore from 'assets/images/store/AppStore.png'
import GooglePlay from 'assets/images/store/GooglePlay.png'
import { aboutLinks, socialLinks, discoveryLinks } from '../links'

import './styles.scss'

const About = () => (
  <div className="container">
    <section className="footer__section footer__section--about text-grey-2">
      <div className="footer__column">
        <h4 className="footer__subtitle">Sobre o iFood</h4>
        <ul className="footer__links-list">
          {aboutLinks.map((link) => (
            <li className="footer__list-item" key={link.title}>
              <a
                href={link.href}
                className="footer__text-link font-semi-bold"
                title={link.title}
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer__column">
        <h4 className="footer__subtitle">Muito mais que um Pedido</h4>
        <ul className="footer__links-list">
          {discoveryLinks.map((link) => (
            <li className="footer__list-item" key={link.title}>
              <a
                href={link.href}
                target="_blank"
                className="footer__text-link font-semi-bold"
                title={link.title}
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer__column footer__hide" />
      <div className="footer__column">
        <h4 className="footer__subtitle">Social</h4>
        <ul className="footer__social">
          {socialLinks.map((link) => (
            <li key={link.label}>
              <a
                href={link.href}
                className="footer__social-icon"
                role="button"
                aria-label={link.label}
                aria-hidden="false"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>{link.icon}</span>
                <p>{link.label}</p>
              </a>
            </li>
          ))}
        </ul>
        <h4 className="footer__subtitle">Baixe o App</h4>
        <ul className="footer__stores">
          <li className="store__ios">
            <a
              href="https://apps.apple.com/br/app/ifood-delivery-de-comida/id483017239"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={AppStore} alt="App Store" />
            </a>
          </li>
          <li className="store__android">
            <a
              href="https://play.google.com/store/apps/details?id=br.com.brainweb.ifood"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={GooglePlay} alt="Play Store" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
)

export default About
