import { isServerSide } from './index'

const Parse = isServerSide() ? require('parse/node') : require('parse')

function withParseConfig({ parseConfig = {} }) {
  const { appId, serverURL, jsKey } = parseConfig
  Parse.serverURL = serverURL
  Parse.initialize(appId, jsKey)
  return Parse
}

export default withParseConfig
