import React from 'react'

import Logo from 'assets/svg/ifood-logo.svg'

import './styles.scss'

const Copyright = () => (
  <section className="footer__section footer__section--copyright bg-primary">
    <div className="container">
      <div className="columns">
          <div className="column copyright__logo">
            <Logo />
          </div>
        <div className="column copyright__text">
          <div>
            <p className="text-sm">
              © Copyright 2020 - iFood - Todos os direitos reservados iFood com Agência de
              Restaurantes Online S.A.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Copyright
