import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import './styles.scss'

const Button = ({ type, secondary, to, href, children, className, ...props }) => {
  const checkButtonStyle = useMemo(() => {
    if (secondary) {
      return `button button--secondary ${className}`
    }
    return `button button--primary ${className}`
  }, [secondary, className])

  if (to) {
    return (
      <Link to={to} className={checkButtonStyle} {...props}>
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className={checkButtonStyle}
        {...props}
      >
        {children}
      </a>
    )
  }

  return (
    <button type={type} className={checkButtonStyle} {...props}>
      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  secondary: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}
Button.defaultProps = {
  type: 'button',
  secondary: false,
  to: undefined,
  className: undefined,
  href: undefined,
}

export default Button
